<template>
  <LayoutPillComponent
    name="cities-slug"
    :localizedData="localizedData"
    componentType="linkWithFallback"
    linkType="dynamic"
  >
    {{ title }}
  </LayoutPillComponent>
</template>

<script setup>
import { SharedThumbnailVenueComponent } from '#components'

const props = defineProps({
  data: Object
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))
</script>

<style module>
.root {
  composes: reset-link from global;
  display: block;
}

.heading {
  composes: reset-heading font-size-medium from global;

  padding-left: calc(var(--unit--horizontal) * 2);
  padding-right: calc(var(--unit--horizontal) * 2);

  margin-bottom: var(--unit--spacer);
}

.label {
  composes: font-size-x-small from global;
  text-transform: uppercase;

  padding-left: calc(var(--unit--horizontal) * 2);
  padding-right: calc(var(--unit--horizontal) * 2);

  margin-bottom: var(--unit--spacer);
}
</style>

<template>
  <div
    v-if="cities?.length"
    :class="$style.root"
  >
    {{ $t('label.citiesInCluster', { count: cities?.length, cluster: title }) }}

    <div :class="$style.cities">
      <PagesClustersSlugCitiesCityComponent
        v-for="entry in cities"
        :data="entry"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  localizedData: Object
})

const title = computed(() => i18nGetLocalizedTitle(props?.localizedData))

const cities = computed(() => {
  return props?.localizedData?.city_entries?.data
})

</script>

<style module>
.root {
  composes: font-size-medium from global;
  --pill--border-color: transparent
}

.citiesInCluster sup {
  color: var(--color--gray--mid);
}

.cities {
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
  margin-top: var(--unit--vertical);
}
</style>
